// Clinic Colors
$primary-color: "%%Primary%%";
$secondary:"%%Secondary%%";
$tertiary: "%%Tertiary%%"; 

// Theme Neutrals
$white: #fff;
$black: #000;
$neutral-dk: #5E6B7D;
$neutral-lt: #EFF4F9; 

@import "../../variables/color-functions-base"; // append -base for color replacement

$primary-color-invert: "%%Tertiary%%";
$secondary-color-invert: "%%Tertiary%%";

// Bulma Overrides
$text: $neutral-dk;
