
body {
	background-color: $tertiary;
}


.header {
    .main-logo img {
        max-width: 260px;
    }

    .fixed-header & {
        background-color: $tertiary;
    }

    @include desktop {
         .navbar-container .navbar .navbar-menu {
            background-color: $tertiary;
        }
    }

    .menu-link:hover {
		@include until( $desktop ){
			color: $secondary-light;
		}
	}

    
    .navbar-link.menu-link.is-current,
	.dropdown-item.is-current .dropdown-link,
	.no-dropdown.is-current .menu-link {
		position: relative;
		color: $secondary-light;
    }
    
    @include desktop {
        .navbar-link.menu-link.is-current,
        .dropdown-item.is-current .dropdown-link,
        .no-dropdown.is-current .menu-link {
            position: relative;
            color: $secondary;
        }
    }

    .appointment-cta-2 .requestAppt-cta {
        background: transparent;

        &:hover {
            background: $white;
            color: $secondary;
        }
    }
}

// Forms 
.module-forms {

	.input, .select, .select::before {
		background: transparent;
	}
	
	.select select {
		border-top: solid 1px;
		border-left: solid 1px;
		border-right: solid 1px;
		border-color: $neutral-dk;
	}
	
	label.label {
		font-weight: normal;
	}

	textarea.textarea {
		background: transparent;
	}
	
}



//Theme 4 Overrides
.page-header {
	@include mobileSidePadding;
	.page-title {
		color: $black;
		text-align: left;

		@include tablet {
			font-size: 48px;
		}
	}
}



.three-col-carousel {
	background: $white;
}

.contact-map .contact-map-details-column {
	border-top: none;
	// background: #fff;
}

.footer, .footer-container {
	background: $tertiary;
}

.one-col-info.with-bg {
	background: $primary-color;
}

.one-col-promo {
	border-top: none;

	.one-col-promo-container {
        max-width: 1200px;
        background-color: $tertiary;

		@include desktop {
			padding-left: 100px;
			padding-right: 100px;
		}

		@include widescreen {
			padding-left: 200px;
			padding-right: 200px;
		}
	}

	.one-col-promo-body {
		max-width: none;
	}
}

.leadership-cards {
	background: $tertiary;
	@extend %brand-border-top;
}

.blog-featured {

	.blog-featured-caption {
		background: $secondary;

	}

	.blog-featured-title a,
	.blog-featured-link {
		color: $white;

		&:hover {
			color: $secondary-light;
		}
	}

}

.one-col-info {
    .one-col-info-content {
        background-color: $white;
    }
}

.page-blocks.base-content {
    padding: 40px 0;
    background: $white;

    @include tablet {
        padding-top: 80px;
    }
}

.leadership-cards,
.team-cards {

    .slick-slide > div {
        position: relative;
    }

    .group-name {
        text-align: center;

        @include tablet {
            text-align: left;
        }
    }

    .group-name h3{
        color: $secondary;
        border-bottom: solid 1px;
        border-bottom-color: $neutral-dk;
        display: inline-block;
        padding-right: 3em;
        padding-bottom: .5em;
        padding: 0 3em .5em;
        font-size: 24px; 

        @include tablet {
            font-size: 32px;
            padding-left: 0;
        }

        @include desktop {
            margin-left: 28px;
        }

        @include widescreen {
            margin-left: 10px;
        }

        @include fullhd {
            margin-left: 34px;
        }

        @include until( $tablet ){
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate( -50%, -50% );
        }
    }
}

//Contact Map

.contact-map.multisite {
    .contact-map-details-group {
        display: flex;
        flex-direction: column;
        height: 100%;

        .contact-map-details-hours {
            margin-top: auto;
        } 

        .contact-map-details-email {
            margin: auto 0 20px;
        }
    }

    .multisite-location-social {
        display: none;
    }

    @include desktop {
        .contact-map-details {
            padding-left: 0;
            padding-right: 0;
            width: 90%;
        }

        .mutisite-map-contact-area {
            width: 100%;
            max-width: 800px;
        }

        .contact-map-details-group {
            > div {
                padding-left: 2em;
                &::before {
                left: 0;
                }
            }
        }
    }

    .contact-map-details-hours-copy {
        span {
            font-weight: bold;
        }
    }
}
