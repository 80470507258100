.module-contact.module-forms {
	.control button.button {
		background: $white;
		border-color: $white;

		&:hover {
			border-color: $secondary;
		}
		}

}