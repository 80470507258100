.vr-signup {
	padding: 80px 0 40px;

	@include tablet {
		padding: 145px 0 148px;
	}

	h2 {
		text-align: center;
		margin-bottom: 3em;
	}

}