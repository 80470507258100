.vr-hero {
	background: url( "/img/vitalrads/vr-hero.png") no-repeat 50% 50%/cover;
	width: 100%;
	height: 600px;
	position: relative;


	@include tablet {
		padding: 80px;
	}

	@include desktop {
		height: 750px;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba( 0, 0, 0, .3);
	}

	.vr-hero-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include tablet {
			align-items: flex-start;
		}
	}
}

#homeLoginView {
	position: relative;
	
	.form-title-1 {
		color: $white;
		text-align: center;
	}

	.form-title-1, .panel-footer {
		margin-bottom: 2rem;
	}

	#logginContainer {
		width: 400px;
		max-width: 100%;

	}
	#formPanel {
		// background: rgba( 255, 255, 255, .3);
	}

	.label {
		color: $white;
	}

	.field, .checkbox {
		margin-bottom: 1.5rem;
	}

	input.input {
		background: $white;
		border-bottom: none;
		filter: none;
	}
	input:-webkit-autofill {
		background-color: $white;
	}

	.checkbox {
		display: block;
		vertical-align: middle;
	}

	.text-checkbox {
		color: $white;
	}

	input.submit.button {
		border: solid 1px;
		border-color: $secondary;
		display: block;
		padding-left: 40px;
		padding-right: 40px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;

		@include tablet {
			width: auto;
		}

		&:hover {
			border-color: $secondary-darker;
		}
	}

	.login-links {
		@include mobileSidePadding;
		text-align: center;
		p {
			@extend %body-2;
			letter-spacing: .07em;
		}
		a {
			color: $white;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}