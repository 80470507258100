.services-list {
    @include tablet {
        .services-list-card .services-list-icon.p-icon {
            top: 0;
        }

        .services-list-card-grid-container.card-grid-container {
            justify-content: center;
            .services-list-column.column.is-6-tablet {
                width: auto;
                flex: 0 1 auto;
                max-width: 50%;
            }
        }
    }

    @include until( $tablet ){
        h3.services-list-card-title {
            cursor: default !important;
            &:hover {
                background-color: $primary-color;
            }
            &::after {
                content: none;
            }
        }
    }
}
