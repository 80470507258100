.reset-password.module-forms {
	max-width: 1200px;
	margin: 0 auto;
	text-align: center;
	border: solid 1px #979797;
	padding: 40px 20px;

	@include tablet {
		padding: 56px 125px;
	}

	@include desktop {
		padding: 112px 225px;
	}

	h3 {
		font-weight: 400;
		font-size: 24px;
		color: $black;

		@include tablet {
			font-size: 36px;
		}
	}

	p {
		@include tablet {
			font-size: 24px;
		}
	}

	form {
		padding-left: 0;
		padding-right: 0;
	}

	input.input {
		border: solid 1px $neutral-dk;

		&::placeholder {
			text-align: center;
			color: $neutral-dk;
		}
	}

}